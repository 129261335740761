export enum PLATFORMS {
	WINDOWS = "windows",
	RPM = "rpm",
	DEBIAN = "debian",
	LINUX = "linux",
	POWERSHELL = "powershell",
	DARWIN = "darwin",
	DOCKER = "docker",
	AIX = "aix",
	RPM_LEGACY = "rpmLegacy",
	SUSE = "suse",
	SOLARIS = "solaris",
	OPENSHIFT = "openShift",
}

export enum containerSteps {
	CREATE = "create",
	HELM = "helmchart",
	COPY_CONFIGMAPS = "copy_configmaps",
	INTEGRATE_CONFIGMAPS = "integrate_configmaps",
	INTEGRATE_CONFIGMAPS_SCRIPT = "integrate_configmaps_script",
	RESTART_ISTIO = "restart_istio",
	INJECT_ISTIO = "inject_istio",
	RESTART_DEPLOYMENT = "restart_deployment",
	UPGRADE = "upgrade",
	COPY_TELEMETRY = "copy_telemetry",
	CLEANUP = "cleanup",
}
export const installScript = "Installation Script";

export const manualInstall = "Manual Installation";

export enum ARCHITECTURE_TYPE {
	X86_64 = "x86_64",
	ARM64 = "arm64",
	PPC = "ppc",
}

export enum AGENT_TYPES {
	CT_AGENT = "CT_AGENT",
	INSTALL_SCRIPT = "INSTALL_SCRIPT",
	GATEWAY_INSTALL_SCRIPT = "CT_GATEWAY_INSTALL_SCRIPT",
	GATEWAY = "CT_GATEWAY",
	CT_CONTAINER_AGENT = "CT_CONTAINER_AGENT",
	CT_USER_AGENT = "CT_USER_AGENT",
	CT_REMOTE_CONNECTOR = "CT_REMOTE_CONNECTOR",
	CT_LEGACY_AGENT = "CT_LEGACY_AGENT",
}

export enum AGENT_TYPES_RADIO_BUTTON {
	CT_AGENT = "Server Agent",
	CT_USER_AGENT = "User Agent",
	CT_CONTAINER_AGENT = "Container Security",
	CT_LEGACY_AGENT = "Server Agent",
}

export const agentTypeValues = {
	CT_AGENT: "server",
	CT_USER_AGENT: "user",
	CT_REMOTE_CONNECTOR: "connector",
	CT_CONTAINER_AGENT: "containers",
	CT_LEGACY_AGENT: "server",
};

export type SupportedCoreOS = Exclude<
	PLATFORMS,
	PLATFORMS.LINUX | PLATFORMS.POWERSHELL
>;

export const CORE_OS: Record<SupportedCoreOS, string> = {
	[PLATFORMS.WINDOWS]: "Windows",
	[PLATFORMS.RPM]: "RPM",
	[PLATFORMS.DEBIAN]: "DEBIAN",
	[PLATFORMS.DARWIN]: "Mac",
	[PLATFORMS.DOCKER]: "Kubernetes Containers",
	[PLATFORMS.AIX]: "AIX",
	[PLATFORMS.RPM_LEGACY]: "RHEL 6",
	[PLATFORMS.SUSE]: "SUSE",
	[PLATFORMS.SOLARIS]: "Solaris",
	[PLATFORMS.OPENSHIFT]: "OpenShift Containers ",
};

export const SUPPORTED_OS_VERSIONS: Record<
	string,
	string[] | Record<string, string[]>
> = {
	[PLATFORMS.WINDOWS]: {
		"Server (64-bit)": ["2008 R2", "2012", "2012 R2", "2016", "2019", "2022"],
	},
	[PLATFORMS.RPM]: {
		CentOS: ["7 and above"],
		"Oracle Linux": ["7.x"],
		RHEL: [
			"RHEL 7.3+ works only with vCloud",
			"8.x+ supports both vCloud and Azure environments",
		],
		"Rocky Linux": ["8.x", "9.x"],
	},
	[PLATFORMS.DEBIAN]: { Debian: ["9"], Ubuntu: ["18", "20"] },
	[PLATFORMS.DARWIN]: ["arm64"],
	[PLATFORMS.AIX]: ["7.1", "7.2"],
	[PLATFORMS.RPM_LEGACY]: ["6"],
	[PLATFORMS.SUSE]: ["15"],
	[PLATFORMS.SOLARIS]: ["11"],
};

export enum environments {
	KUBERNETES = "Kubernetes ",
	ISTIO = "istio",
	HELM = "helm",
	OPENSHIFT = "OpenShift",
}
export const ENVIRONMENT: Record<environments, string> = {
	[environments.KUBERNETES]: "Kubernetes",
	[environments.OPENSHIFT]: "OpenShift",
	[environments.ISTIO]: "Istio",
	[environments.HELM]: "Helm",
};

export const SUPPORTED_ENV_VERSIONS: Record<string, string[]> = {
	[environments.KUBERNETES]: ["1.21 and above", "(aks, eks, gke)"],
	[environments.OPENSHIFT]: ["4.0 and above"],
	[environments.ISTIO]: ["1.18.0 and above"],
	[environments.HELM]: ["3.13.2 and above"],
};

export const EMPTY_STRING = "";
