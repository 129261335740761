import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
	Stack,
	Tooltip,
	TooltipProps,
	styled,
	tooltipClasses,
} from "@mui/material";
import {
	DataGridProProps,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridCellCheckboxRenderer,
	GridColDef,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	useGridApiRef,
} from "@mui/x-data-grid-pro";
import { UseMutationResult } from "@tanstack/react-query";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { SortOrder } from "modules/core/types";
import {
	CustomDetailPanelToggle,
	DataGrid,
} from "modules/data-grid/components/data-grid";
import { TemplateDetail } from "pages/templates/components/template-detail/TemplateDetail";
import { Template } from "pages/templates/types";
import { useCallback, useEffect, useMemo, useState } from "react";

const FreeWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "none",
	},
});

export interface TemplateListProps {
	list: Array<Template>;
	columns: GridColDef[];
	mutation: UseMutationResult<any, Error, any, unknown> | undefined;
	rowClickCallback: Function;
	multiselect?: boolean;
	onSelectCallback?: Function;
	expanderPosition?: string;
	isLoading?: boolean;
	onSortChange: (sort: Array<SortOrder>) => void;
	autoHeight?: boolean;
	sorting?: boolean;
	canDisableSelection?: boolean;
	hasPermission?: boolean;
	setPage?: (pageNumber: number) => void;
	setPageSize?: (pageSize: number) => void;
	page?: number;
	pageSize?: number;
	totalCount?: number;
	paginationMode?: "client" | "server";
}

export const ListTable = ({
	list,
	columns,
	mutation,
	rowClickCallback,
	multiselect,
	onSelectCallback,
	expanderPosition = "end",
	isLoading,
	onSortChange,
	autoHeight,
	sorting = true,
	canDisableSelection = false,
	hasPermission,
	setPageSize,
	setPage,
	page,
	pageSize,
	totalCount,
	paginationMode = "server",
}: TemplateListProps) => {
	const { isFeatureEnabled } = useFeatureFlagControl(
		FEATURES.EDIT_TEMPLATE_DETAIL
	);

	const tableColumns = useMemo(() => {
		let cols = [];
		if (expanderPosition === "start") {
			cols = [
				{
					...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
					renderCell: (params: GridRenderCellParams<any, any, any>) => (
						<CustomDetailPanelToggle id={params.id} value={params.value} />
					),
				},
				{
					...GRID_CHECKBOX_SELECTION_COL_DEF,
					renderCell: (params: GridRenderCellParams<any, any, any>) => {
						return (
							<FreeWidthTooltip
								PopperProps={{ disablePortal: true }}
								placement={"right"}
								title={window.getCTTranslatedText(
									params.row.assignedByTagBasedPolicy
										? "TemplatesAssignedByPoliciesCannotBeRemoved"
										: ""
								)}
							>
								{params.row.assignedByTagBasedPolicy && canDisableSelection ? (
									<Stack>
										<CheckBoxOutlineBlankIcon
											sx={{ width: "20px", cursor: "not-allowed" }}
											color="disabled"
										/>
									</Stack>
								) : (
									<GridCellCheckboxRenderer {...params} />
								)}
							</FreeWidthTooltip>
						);
					},
					type: "singleSelect",
				},
				...columns,
			];
		} else {
			cols = [
				{
					...GRID_CHECKBOX_SELECTION_COL_DEF,
					renderCell: (params: GridRenderCellParams<any, any, any>) => {
						return (
							<FreeWidthTooltip
								PopperProps={{ disablePortal: true }}
								placement={"right"}
								title={window.getCTTranslatedText(
									params.row.assignedByTagBasedPolicy
										? "TemplatesAssignedByPoliciesCannotBeRemoved"
										: ""
								)}
							>
								{params.row.assignedByTagBasedPolicy && canDisableSelection ? (
									<Stack>
										<CheckBoxOutlineBlankIcon
											sx={{ width: "20px", cursor: "not-allowed" }}
											color="disabled"
										/>
									</Stack>
								) : (
									<GridCellCheckboxRenderer {...params} />
								)}
							</FreeWidthTooltip>
						);
					},
					type: "singleSelect",
				},
				...columns,
				{
					...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
					renderCell: (params: GridRenderCellParams<any, any, any>) => (
						<CustomDetailPanelToggle id={params.id} value={params.value} />
					),
				},
			];
		}
		return cols;
	}, [columns, expanderPosition, canDisableSelection]);

	const apiRef = useGridApiRef();

	const handleRowClickEvent: GridEventListener<"rowClick"> = (
		params // GridRowParams
	) => {
		if (params?.row?.templateId) {
			apiRef?.current?.toggleDetailPanel(params?.id);
			if (!multiselect) {
				rowClickCallback(params?.row);
			}
		}
	};

	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps["getDetailPanelContent"]>
	>(
		({ row }) => {
			return (
				<>
					<TemplateDetail
						templateId={row?.templateId}
						viewOnly={!isFeatureEnabled}
						hideMetadata={true}
						isEmbeddedInDataGrid
					/>
				</>
			);
		},
		[isFeatureEnabled]
	);

	const getDetailPanelHeight = useCallback<
		NonNullable<DataGridProProps["getDetailPanelHeight"]>
	>(() => "auto" as const, []);

	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	const selectedData: Array<Template> | undefined = useMemo(() => {
		const selectedList = list?.filter((row: Template) => {
			return selection.indexOf(row.templateId) !== -1;
		});

		return selectedList;
	}, [selection, list]);

	useEffect(() => {
		if (onSelectCallback) {
			onSelectCallback(selectedData);
		}
	}, [onSelectCallback, selectedData]);

	return (
		<Stack
			sx={{
				width: "100%",
				flex: 1,
				overflow: "hidden",
			}}
		>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: sorting
							? [
									{ field: "oobTemplate", sort: "asc" },
									{ field: "templateName", sort: "asc" },
								]
							: [],
					},
				}}
				rows={getUniqueListBy(list, "templateId") ?? []}
				rowCount={totalCount ?? 0}
				rowHeight={64}
				columns={tableColumns}
				getRowId={({ templateId }: Template) => templateId}
				mutation={mutation}
				onRowClick={handleRowClickEvent}
				rowThreshold={0}
				getDetailPanelHeight={getDetailPanelHeight}
				getDetailPanelContent={getDetailPanelContent}
				// columnTypes={{
				//   [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type as string]:
				//     GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
				// }}
				checkboxSelection={multiselect}
				rowSelectionModel={selection}
				onRowSelectionModelChange={selectionModel => {
					setSelection(selectionModel);
				}}
				apiRef={apiRef}
				isLoading={isLoading}
				sortingMode={sorting ? "server" : "client"}
				onSortChange={onSortChange}
				onPageChange={setPage}
				page={page}
				pageSize={pageSize}
				onPageSizeChange={setPageSize}
				pagination
				paginationMode={paginationMode}
			/>
		</Stack>
	);
};
